/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/


* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.image {
  opacity: 1;
  display: block;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.header-cert {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 100px;
  font-family: 'Public Sans', sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  color: #5b80e1;
}

.header-cert span {
  background-color: black;
  color: white;
  padding: 2px 4px;
  text-decoration: none;
}

.header-cert u {
  text-decoration: underline;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  padding: 0 5px;
}

.value {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: black;
}

.card {
  width: 100%;
  padding: 24px;
  margin: 0 auto;
  max-width: 600px;
}

@media screen and (max-width: 600px) {
  .header-cert {
    max-width: 400px;
    padding: 20px 5px 5px 30px;
    width: 100%;
    font-size: 27px;
    line-height: 24px;
    font-weight: 700;
    padding-left: 3px;
  }
  .header-cert span {
    padding: 2px 4px;
    margin-left: 4px;
  }
  .card {
    padding: 1px;
    margin: 0;
  }
}

.middle {
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: black;
}

.containerPlus {
  position: relative;
  text-align: center;
  color: #222;
  cursor: pointer;
}

.modalContainer {
  background-color: black;
  position: relative;
  width: 100%;
  max-width: 400px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.modalDownload {
  position: relative;
  width: 400px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.modalImage {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.portrait {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.landscape {
  display: inline-block;
  width: 100%;
  position: absolute;
  height: 100vh;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



.modalCentered {
  position: absolute;
  width: 100%;
  font-size: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: rgba(52, 52, 52, 0.6);
  /* bottom: 0; */
  word-break: break-word;
  overflow-wrap: break-word;
  padding: 2px 2px;
  box-sizing: border-box;
  
  bottom: 5%;
}


.modalCenteredDownload {
  position: absolute;
  width: 100%;
  font-size: 12px;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(52, 52, 52, 0.3);
}

.bottom {
  bottom: 0px;
}

.top {
  top: 72%;
}

.modalCenteredDiv {
  position: absolute;
  width: 100%;
  background-color: #343434;
  opacity: 0.4;
  color: white;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gridcontainer {
  width: auto;
  max-width: 450px;
  -ms-flex-line-pack: center;
  align-content: center;
}

.fowardarrowCentered {
  position: absolute;
  width: 100%;
  color: white;
  top: 50%;
  float: right;
}

.details {
  position: absolute;
  top: 50%;
  right: 0px;
  text-align: center;
}

.technology {
  padding-top: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  font-size: 19px;
  border-top: 2px dotted #5c81e4;
}

.validation-number {
  border-bottom: 2px dotted #5c81e4;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
.latlong {
  padding-left: 1px;
  padding-right: 1px;
  font-size: 16px;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom: 2px dotted #5c81e4;
}

.checkbox {
  position: absolute;
  top: 5px;
  left: 6px;
  z-index: 1;
  width: 15px;
  height: 15px;
}

.userData {
  padding-top: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 768px) {
  .checkbox {
    left: 17px;
  }
}
.verification-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-top: 25px;
  padding-bottom: 10px;
}

.verification-inner {
  max-width: 100%;
  margin: 0 auto;
}

.verification-content {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: center;
  padding: 8px 24px;
}

.verification-info {
  width: 50%;
}

.status-container {
  margin-bottom: 12px;
  margin-top: 4px;
  text-align: left;
}

.status-text {
  text-align: left;
}

.info-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  gap: 4px;
  margin-right: 120px;
}
.info-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 30px;
}

.info-row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 80px 1fr;
  grid-template-columns: 80px 1fr;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.label {
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.response-container {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.qr-container {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.qr-image {
  max-width: 150px;
  height: auto;
  margin-top: -12px;
  margin-bottom: 1px;
  /* border: 1px solid #5c81e4; */
  /* display: none; */
}

@media only screen and (max-width: 600px) {
  .latlong {
    padding-left: 1px;
    padding-right: 1px;
    font-size: 16px;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 10px;
    border-bottom: 2px dotted #5c81e4;
  }
  .label {
    font-size: 16px;
  }
  .verification-container {
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
  }
  .status-section {
    font-size: 15px;
    padding: 0px;
  }
  .verification-content {
    max-width: 400px;
    margin: 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1px 0px 2px 0px;
    gap: 15px;
    font-size: 16px;
  }
  .status-text {
    font-size: 15px;
    padding-left: -3px;
  }
  .modalContainer {
    background-color: black;
    position: relative;
    width: 100%;
    /* height: 100vh; */
  }
  .modalImage {
    display: block;
    width: 100%;
  }
  .gridcontainer {
    width: auto;
    max-width: 380px;
  }
  .fowardarrowCentered {
    position: absolute;
    width: 100%;
    color: white;
    top: 50%;
    float: right;
  }
  .validation-number {
    max-width: 400px;
    margin: 5px auto;
    font-size: 20px;
    /* padding-top: 7.5px; */
  }
  .qr-image {
    max-width: 80px;
    height: auto;
    display: block;
    margin-right: -8px;
    margin-top: 0.1px;
  }
  .userData {
    padding: 5px 0px 2.5px 0px;
    max-width: 400px;
    margin: 0 auto;
    font-size: 16px;
  }
  .technology {
    padding-top: 8px;
    /* padding-bottom: 7px; */
    max-width: 400px;
    margin: 0 auto;
    font-size: 17px;
  }
  .info-section {
    display: block;
  }
}

@media screen and (max-width: 390px) {
  .header-cert {
    font-size: 24px;
  }
  .userData {
    max-width: 350px;
  }
  .verification-content {
    max-width: 350px;
  }
  .validation-number {
    font-size: 21px;
    max-width: 350px;
  }
  .technology {
    font-size: 16px;
    max-width: 350px;
  }
  .qr-image {
    max-width: 80px;
  }
  .verification-content {
    gap: 10px;
  }
}
@media screen and (max-width: 320px) {
  .header-cert {
    font-size: 20px;
  }
  .userData {
    max-width: 300px;
  }
  .verification-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .validation-number {
    font-size: 18px;
  }
  .technology {
    font-size: 15px;
  }
  .qr-image {
    max-width: 70px;
  }
  .verification-content {
    gap: 15px;
  }
}

.example {
  display: -ms-grid;
  display: grid;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: -o-linear-gradient(top, white, black);
  background: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
  background: linear-gradient(to bottom, white, black);
}